<template>
  <div
    class="option-select"
    :style="styleDisplay"
  >
    <div class="option-select__wrapper">
      <ul :class="triggerClassListenerOnScroll">
        <li
          v-if="dataList.err"
          class="option-select__element"
        >
          <span class="option-select__option">something went wrong</span>
        </li>
        <li v-else-if="showLoadingSearch">
          <loader
            :position-left="positionLoader"
            color-class="l-grey"
          />
        </li>
        <li
          v-else-if="dataList.data.length === 0"
          class="option-select__element"
        >
          <span
            class="option-select__option"
            v-html="message"
          />
        </li>
        <li
          v-for="(data, index) in dataList.data"
          v-else
          :key="'option-select__element-'+ index"
          data-checker
          tabindex="-1"
          :class="decideClassFirsHover(index)"
          class="option-select__element"
          @click="$emit('click', data)"
          @event="$emit('event', $event)"
        >
          <span
            class="option-select__option"
            :class="{'do-capitalize': capitalize}"
          >{{
            setDataValue(data, keyValue)
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '../new-loader/Loader';

export default {
  name: 'OptionSelect',
  components: { Loader },
  props: {
    /** must an object which has data key and err key */
    dataList: {
      type: Object,
      required: true,
    },
    /** the key object for showing on ul li value from object data list */
    keyValue: {
      type: String,
      required: true,
    },
    /** style to show and not the div such as display none and block */
    styleDisplay: {
      type: String,
      required: true,
    },
    showLoadingSearch: {
      type: Boolean,
      required: true,
      default: false,
    },
    triggerClassListenerOnScroll: {
      type: String,
      required: true,
    },
    positionLoader: {
      type: String,
      default: '40%',
    },
    /** class hover must be unique */
    decideClassHover: {
      type: String,
      required: true,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'not found',
    },
  },
  methods: {
    /**
     *
     * @param index {number}
     * @return {string}
     */
    decideClassFirsHover(index) {
      return index === 0 ? this.decideClassHover : '';
    },
    /**
     *
     * @param data {object}
     * @param keys {string} // should seperated by comma without space
     * @return {string}
     */
    setDataValue(data, keys) {
      const newKeys = keys.split(',');
      const result = [];
      for (const newKey of newKeys) {
        let val = data[newKey.trim()];
        if (this.capitalize) {
          val = val.toLowerCase();
        }
        result.push(val);
      }
      return result.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
